import React, { useContext } from "react";
import './followers.css';
import { UserContext } from "../../../components/userContext"; 
import { useNavigate } from "react-router-dom";

const Following = () => {
  
  const navigate = useNavigate();
  const { followingList } = useContext(UserContext);

  const handleUsernameProfile = (username) => {
    navigate(`/profile/${username}`)
  }

  return (
    <div className="followers-page">
      <div className="followers-container">
        <h1 className="followers-followers">Following</h1>
        <p className="followers-count">{followingList.length}</p>
      </div>
      <div className="followerslist-container">
        {followingList.map((username, index) => (
          <p key={index} className="follower-username" onClick={() => handleUsernameProfile(username)}>@{username}</p>
        ))}
      </div>
    </div>
  );
};

export default Following;
