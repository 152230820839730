/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection, query, where, getDocs, doc, setDoc, deleteDoc, getDoc, onSnapshot
} from "firebase/firestore";
import { db } from "../../firebase";
import NavBar from "../../components/navbar/navbar";
import Button from "../../components/button";
import { UserContext } from "../../components/userContext";
import './otherProfile.css';

const OtherProfile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [otherUser, setOtherUser] = useState(null);
  const [userPhotos, setUserPhotos] = useState([]);
  const { currentUser, userProfile } = useContext(UserContext);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user profile data
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("username", "==", username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Assuming username is unique, so we expect only one document
          const userData = querySnapshot.docs[0].data();
          userData.id = querySnapshot.docs[0].id; // Add id to user data for later use
          setOtherUser(userData);

          // Fetch user photos
          const userPhotosRef = collection(db, `users/${userData.id}/userPhotos`);
          const userPhotosSnapshot = await getDocs(userPhotosRef);
          const photos = userPhotosSnapshot.docs.map((doc) => ({ id: doc.id, url: doc.data().url }));
          setUserPhotos(photos);

          // Check if the current user is following this user
          const followerDocRef = doc(db, `users/${userData.id}/followers/${currentUser.uid}`);
          const followerDoc = await getDoc(followerDocRef);
          if (followerDoc.exists()) {
            setIsFollowing(true);
          }

          // Fetch follower and following counts
          const followersRef = collection(db, `users/${userData.id}/followers`);
          const followingRef = collection(db, `users/${userData.id}/following`);

          // Listen for real-time updates
          const unsubscribeFollowers = onSnapshot(followersRef, (snapshot) => {
            setFollowerCount(snapshot.size);
          });
          const unsubscribeFollowing = onSnapshot(followingRef, (snapshot) => {
            setFollowingCount(snapshot.size);
          });

          // Clean up listeners on unmount
          return () => {
            unsubscribeFollowers();
            unsubscribeFollowing();
          };
        } else {
          console.log("No matching documents.");
          // Handle case where no user with that username exists
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (username && currentUser) {
      fetchUserData();
    }
  }, [username, currentUser]);

  const handleFollow = async () => {
    if (!currentUser || !otherUser) {
      console.error("No authenticated user or other user not loaded");
      return;
    }

    const currentUserRef = doc(db, "users", currentUser.uid);
    const otherUserRef = doc(db, "users", otherUser.id);

    try {
      if (isFollowing) {
        // Unfollow: remove the documents from Firestore
        const followersRef = doc(db, `users/${otherUser.id}/followers/${currentUser.uid}`);
        await deleteDoc(followersRef);

        const followingRef = doc(db, `users/${currentUser.uid}/following/${otherUser.id}`);
        await deleteDoc(followingRef);

        setIsFollowing(false);
      } else {
        // Follow: add the documents to Firestore
        const followersRef = collection(otherUserRef, "followers");
        await setDoc(doc(followersRef, currentUser.uid), {
          username: userProfile.username,
        });

        const followingRef = collection(currentUserRef, "following");
        await setDoc(doc(followingRef, otherUser.id), {
          username: otherUser.username,
        });

        setIsFollowing(true);
      }
    } catch (error) {
      console.error("Error following/unfollowing user:", error);
    }
  };

  if (!otherUser) {
    return <p>Loading...</p>; // Optional loading state
  }

  const handleOtherFollowList = () => {
    navigate(`/followers/${username}`)
  }

  const handleOtherFollowingList = () => {
    navigate(`/following/${username}`)
  }

  return (
    <div className="op-page">
      <NavBar />
      <div className="op-container">
        <p className="op-profileusername">@{otherUser.username}</p>
        <p className="opprofilename">{otherUser.name}</p>
        <div className="op-profilePhoto">
          {otherUser.profilePhoto && (
            <img src={otherUser.profilePhoto} className="op-profilephoto-img" />
          )}
        </div>
        <div className="op-profilebio">
          <p>{otherUser.bio}</p>
        </div>
        <p className="op-followers"onClick={handleOtherFollowList}>FOLLOWERS</p> 
        <p className="op-following"onClick={handleOtherFollowingList}>FOLLOWING</p> 
        <p className="op-followersnum"onClick={handleOtherFollowList}>{followerCount}</p> 
        <p className="op-followingnum"onClick={handleOtherFollowingList}>{followingCount}</p> 
        <Button
          text={isFollowing ? "Following" : "Follow"}
          className="op-followbutton"
          onClick={handleFollow} />
      </div>
      <div className="profile-gallery">
        {/* Display user's photos */}
        {userPhotos.length > 0 ? (
          userPhotos.map((photo) => (
            <div key={photo.id} className="profile-gallery-photo">
              <img src={photo.url} alt="User photo" className="gallery-photo-img" />
            </div>
          ))
        ) : (
          <p>No photos available.</p>
        )}
      </div>
    </div>
  );
};

export default OtherProfile;
