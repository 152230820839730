/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import './welcome.css'
import Button from "../../../components/button";
import logo from '../../../assets/logo.png'
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();

  const handleWelcomePro = () => {
    navigate('/register');
  }
  return ( 
  <div className="welcome-page">
      
  <img src={logo} className="logo" />
  <div className="welcome-container">
    <h1 className="welcomeZillla">Welcome</h1>
    <p className="createAccount">Create an account.</p>
    <Button
      text='professional'
          className='welcome-pro-button'
          onClick={handleWelcomePro}
    />
    <Button
      text='individual'
      className='welcome-indie-button'
    />
    </div>
    </div>
  )
}

export default Welcome;