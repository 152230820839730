import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState({
    uid: '',
    username: '',
    name: '',
    bio: '',
    profilePhoto: '',
    userPhotos: [] // Added state to manage user photos
  });
  const [followersList, setFollowersList] = useState([]);
  const [followingList, setFollowingList] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        const userRef = doc(db, 'users', user.uid);
        getDoc(userRef).then((docSnapshot) => {
          if (docSnapshot.exists()) {
            setUserProfile((prevState) => ({
              ...prevState,
              uid: user.uid,
              username: docSnapshot.data().username,
              name: docSnapshot.data().name,
              bio: docSnapshot.data().bio,
              profilePhoto: docSnapshot.data().profilePhoto
            }));
            // Fetch user photos
            fetchUserPhotos(user.uid);
            // Fetch followers
            fetchFollowers(user.uid);
            // Fetch following
            fetchFollowing(user.uid);
          }
        }).catch((error) => {
          console.error("Error fetching user data:", error);
        });
      } else {
        setCurrentUser(null);
        setUserProfile({
          uid: '',
          username: '',
          name: '',
          bio: '',
          profilePhoto: '',
          userPhotos: []
        });
        setFollowersList([]); // Clear followers list on sign out
        setFollowingList([]); // Clear following list on sign out
      }
    });

    const fetchUserPhotos = async (uid) => {
      const photosRef = collection(db, 'userPhotos');
      const q = query(photosRef, where('uid', '==', uid));
      const querySnapshot = await getDocs(q);
      const photos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUserProfile(prevState => ({ ...prevState, userPhotos: photos }));
    };

    const fetchFollowers = async (uid) => {
      try {
        const followersRef = collection(db, `users/${uid}/followers`);
        const followersSnapshot = await getDocs(followersRef);
        const followersData = followersSnapshot.docs.map(doc => doc.data().username);
        setFollowersList(followersData);
      } catch (error) {
        console.error("Error fetching followers:", error);
      }
    };

    const fetchFollowing = async (uid) => {
      try {
        const followingRef = collection(db, `users/${uid}/following`);
        const followingSnapshot = await getDocs(followingRef);
        const followingData = followingSnapshot.docs.map(doc => doc.data().username);
        setFollowingList(followingData);
      } catch (error) {
        console.error("Error fetching following:", error);
      }
    };

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, userProfile, setUserProfile, followersList, followingList }}>
      {children}
    </UserContext.Provider>
  );
};
