import { initializeApp } from "firebase/app";
import { getAuth,  setPersistence, browserSessionPersistence, signOut } from "firebase/auth";
import {
  getFirestore, doc, setDoc, getDocs, collection,
  query, where, updateDoc, collectionGroup, getDoc, deleteDoc
} from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDuzsckQl66rVfz2GS_JYHHMmlCF9xPeFE",
  authDomain: "zillla-tech-72353.firebaseapp.com",
  projectId: "zillla-tech-72353",
  storageBucket: "zillla-tech-72353.appspot.com",
  messagingSenderId: "189781859621",
  appId: "1:189781859621:web:30aae33ec9997d41f74ecc"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)
const storage = getStorage(app)


setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log("Firebase Authentication persistence enabled");
  })
  .catch((error) => {
    console.error("Error enabling Firebase Authentication persistence:", error);
  });

export {
  auth, db, storage, doc, setDoc, getDocs,
  collection, query, where, updateDoc,
  ref, uploadBytes, getDownloadURL, collectionGroup,
  setPersistence, browserSessionPersistence, signOut, getDoc, deleteDoc
}