import React, { useContext } from "react";
import './followers.css';
import { UserContext } from "../../../components/userContext";
import { useNavigate } from "react-router-dom";


const Followers = () => {
  const { followersList } = useContext(UserContext);
  const navigate = useNavigate();

  const handleusernameProfile = (username) => {
    navigate(`/profile/${username}`)
  }

  return (
    <div className="followers-page">
     
      <div className="followers-container">
        <h1 className="followers-followers">Followers</h1>
        <p className="followers-count">{followersList.length}</p>
      </div>
      <div className="followerslist-container">
        {followersList.map((username, index) => (
          <p key={index} className="follower-username" onClick={() => handleusernameProfile(username)}>@{username}</p>
        ))}
      </div>
    </div>
  );
};

export default Followers;
