import React, { useState, useContext } from "react";
import Button from "../../../components/button";
import './uploadPhoto.css';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../components/userContext";
import { storage, db } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";

const UploadPhoto = () => {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [description, setDescription] = useState('');
  const { currentUser, userProfile } = useContext(UserContext);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!currentUser) {
      console.error('Current user not available');
      return;
    }

    if (!userProfile.username) {
      console.error('Username is not defined');
      return;
    }

    try {
      const storageRef = ref(storage, `userPhotos/${currentUser.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Reference to the specific user's photos collection
      const userPhotosRef = collection(db, `users/${currentUser.uid}/userPhotos`);

      await addDoc(userPhotosRef, {
        url: downloadURL,
        description,
        username: userProfile.username,
        createdAt: new Date(),
      });

      // Dispatch a custom event to notify the UserProfile component
      console.log("Dispatching photosUpdated event");
      window.dispatchEvent(new Event('photosUpdated'));

      navigate('/home'); // Navigate appropriately after upload
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  return (
    <div className="uploadphoto-page">
      <div className="uploadphoto-container">
        <h1>Upload Photo</h1>
        <div className="uploadphoto-gallery">
          {filePreview && <img src={filePreview} alt="Preview" className="photo-preview-upload" />}
        </div>
        <input
          type="file"
          id="file-upload"
          className="file-input"
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload" className="custom-file-upload">
          Choose File
        </label>
        <textarea
          placeholder="Description"
          className="upload-description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button
          text='Upload'
          className='upload-button'
          onClick={handleUpload}
        />
      </div>
    </div>
  );
};

export default UploadPhoto;
