import React from "react";

const UserInput = ({ className, placeholder, value, type, onChange }) => {
  const inputStyle = {
    width: '200px',
    height: '30px',
    borderRadius: '10px',
    backgroundColor: 'black',
    color: 'white'
  }

  
  return (
    <input
      className={className}
      placeholder={placeholder}
      value={value}
      style={inputStyle}
      type={type}
      onChange={onChange}
    />
  )
}

export default UserInput;