import React, { useContext } from "react";
import './settings.css';
import { signOut, deleteUser } from "firebase/auth";
import { auth, db, storage } from "../../firebase";  // Ensure correct path
import { UserContext } from "../../components/userContext";  // Ensure correct path
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const { currentUser, setUserProfile } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUserProfile({
        uid: '',
        username: '',
        name: '',
        bio: '',
        profilePhoto: '',
        userPhotos: []
      });
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      if (currentUser) {
        const uid = currentUser.uid;     

        // Delete user document from Firestore
        const userRef = db.collection("users").doc(uid);
        await userRef.delete();

        // Delete user's files from Storage (example assumes files are stored under user's uid)
        const userStorageRef = storage.ref(`users/${uid}`);
        await userStorageRef.delete();

        // Delete user account from Authentication
        await deleteUser(auth.currentUser);

        // Clear user profile and navigate to login
        setUserProfile({
          uid: '',
          username: '',
          name: '',
          bio: '',
          profilePhoto: '',
          userPhotos: []
        });
        navigate("/login");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  return (
    <div className="settings-layout">
      <div className="settings-container">
        <h1>Settings</h1>
        <p>About</p>
        <p>Privacy</p>
        <p onClick={handleLogout} className="logout">Logout</p>
        <p onClick={handleDeleteAccount} className="delete-account">Delete Account</p>
      </div>
    </div>
  );
};

export default Settings;
