import React from "react";

const Button = ({ className, text, onClick }) => {
  const buttonStyle = {
    backgroundColor: 'white',
    color: 'black',
    cursor: 'pointer',
    width: '120px',
    height: '30px',
    borderRadius: '10px',
    border: '2px solid white'
  }



  return (
    <button className={className} onClick={onClick} style={buttonStyle}>
      {text}
   </button>
  )
}

export default Button;