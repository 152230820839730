/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar";
import { collectionGroup, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "./home.css";
import HomeModal from "./homeModal"; // Assuming file name is HomeModal.js
import logo from '../../assets/logo.png'
import UserInput from "../../components/userInput";
const Home = () => {
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null); // State to track selected photo
  const [modalData, setModalData] = useState({}); // State to store modal data

  useEffect(() => {
    const fetchPhotos = async () => {
      const photosCollectionGroup = collectionGroup(db, "userPhotos");
      const photoDocs = await getDocs(photosCollectionGroup);
      const allPhotos = photoDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setPhotos(allPhotos);
    };

    fetchPhotos();
  }, []);

  const openModal = (photo) => {
    setSelectedPhoto(photo.url);
    setModalData({
      description: photo.description,
      username: photo.username
     
    });
  };

  const closeModal = () => {
    setSelectedPhoto(null);
    setModalData({});
  };

  return (
    <div className="home-page">
      <NavBar />
      <div className="top-bar" >
      <img src={logo} className="logo3" />
      <UserInput
        className="search"
        placeholder='search'
      />
</div>

      <div className="home-container">
        {photos.map((photo) => (
          <div key={photo.id} className="home-photo">
            <img
              src={photo.url}
              alt="Photo"
              className="home-photo-img"
              onClick={() => openModal(photo)}
            />
          </div>
        ))}
      </div>
      {selectedPhoto && (
        <HomeModal
          photoUrl={selectedPhoto}
          description={modalData.description}
          username={modalData.username}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default Home;
