import React, { useState, useContext } from "react";
import UserInput from "../../../components/userInput";
import { doc, setDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from "../../../firebase";
import { UserContext } from "../../../components/userContext";
import './username.css'
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";

const Username = () => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const { currentUser, setUserProfile } = useContext(UserContext);
  const navigate = useNavigate();

  const checkUsernameExists = async (username) => {
    const q = query(collection(db, 'users'), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  }

  const handleSave = async () => {
    if (!username) {
      setError('Username cannot be empty');
      return;
    }

    if (currentUser) {
      try {
        const usernameExists = await checkUsernameExists(username);
        if (usernameExists) {
          setError('Username is already taken');
          return;
        }

        const userRef = doc(db, 'users', currentUser.uid);
        await setDoc(userRef, { username }, { merge: true });
        console.log('Username saved successfully');
        
        // Update userProfile context with the new username
        setUserProfile(prevProfile => ({
          ...prevProfile,
          username
        }));

        // Navigate to the appropriate page after saving
        navigate(`/updateprofile`);
      } catch (error) {
        console.error("Error saving username: ", error);
        setError('Failed to save username');
      }
    } else {
      setError('No authenticated user found');
    }
  };

  return (
    <div className="username-page">
      <div className="username-container">
        <p>Add username.</p>
        <UserInput
          placeholder='username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {error && <p className="error-message">{error}</p>}
        <Button
          text='Save'
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default Username;
