import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection, query, where, getDocs
} from "firebase/firestore";
import { db } from "../../../firebase";

const OtherFollowing = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [followingCount, setFollowingCount] = useState(0);
  const [followingList, setFollowingList] = useState([]);

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        // Fetch followers count
        const userRef = collection(db, 'users');
        const q = query(userRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userId = querySnapshot.docs[0].id;
          const followingRef = collection(db, `users/${userId}/following`);
          const followingSnapshot = await getDocs(followingRef);
          const followingData = followingSnapshot.docs.map(doc => doc.data().username);
          setFollowingCount(followingData.length);
          setFollowingList(followingData);
        } else {
          console.log("No matching user found.");
        }
      } catch (error) {
        console.error("Error fetching followers:", error);
      }
    };

    if (username) {
      fetchFollowing();
    }
  }, [username]);

  const handleOPnavUser = (username) => {
    navigate(`/profile/${username}`)
  }

  return (
    <div className="followers-page">
      <div className="followers-container">
        <h1 className="followers-followers">Followers</h1>
        <p className="followers-count">{followingCount}</p>
      </div>
      <div className="followerslist-container">
        {followingList.map((follower, index) => (
          <p key={index} className="follower-username" onClick={() => handleOPnavUser(username)} >@{follower}</p>
        ))}
      </div>
    </div>
  );
};

export default OtherFollowing;
