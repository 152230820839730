import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection, query, where, getDocs
} from "firebase/firestore";
import { db } from "../../../firebase";

const OtherFollower = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [followerCount, setFollowerCount] = useState(0);
  const [followersList, setFollowersList] = useState([]);

  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        // Fetch followers count
        const userRef = collection(db, 'users');
        const q = query(userRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userId = querySnapshot.docs[0].id;
          const followersRef = collection(db, `users/${userId}/followers`);
          const followersSnapshot = await getDocs(followersRef);
          const followersData = followersSnapshot.docs.map(doc => doc.data().username);
          setFollowerCount(followersData.length);
          setFollowersList(followersData);
        } else {
          console.log("No matching user found.");
        }
      } catch (error) {
        console.error("Error fetching followers:", error);
      }
    };

    if (username) {
      fetchFollowers();
    }
  }, [username]);

  const handleOPnavUser = (username) => {
    navigate(`/profile/${username}`)
  }

  return (
    <div className="followers-page">
      <div className="followers-container">
        <h1 className="followers-followers">Followers</h1>
        <p className="followers-count">{followerCount}</p>
      </div>
      <div className="followerslist-container">
        {followersList.map((follower, index) => (
          <p key={index} className="follower-username" onClick={() => handleOPnavUser(username)} >@{follower}</p>
        ))}
      </div>
    </div>
  );
};

export default OtherFollower;
