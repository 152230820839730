/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import logo from '../../../assets/logo.png'
import './register.css'
import UserInput from "../../../components/userInput";
import Button from "../../../components/button";
import { useNavigate, Link } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../../firebase';

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [error, setError] = useState('');
  
  const handleRegister = async () => {
    if (!checkboxChecked) {
      setError('Please agree to the terms.');
      return;
    }
    
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User UID:', user.uid);

      // Reset states after successful registration
      setEmail('');
      setPassword('');
      setCheckboxChecked(false);
      setError('');

      navigate('/username');
    } catch (error) {
      console.error('Error registering user:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('Email is already in use');
      } else {
        setError('Failed to register');
      }
    }
  };

  return (
    <div className="register-page">
      <img src={logo} className="logo" />
      <div className="register-container">
        <h1>Register</h1>
        <UserInput
          placeholder='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <UserInput
          placeholder='password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="terms-row">
          <input
            className="checkbox"
            type="checkbox"
            checked={checkboxChecked}
            onChange={(e) => setCheckboxChecked(e.target.checked)}
          />
          <p>terms and condition</p>
        </div>
        {error && <p className="error-message">{error}</p>}
        <Button
          text='register'
          onClick={handleRegister}
        />
        <Link to='/login'>Login, if already registered.</Link>
      </div>
    </div>
  );
};

export default Register;
