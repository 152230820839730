/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from "react";
import logo from '../../../assets/logo.png';
import UserInput from "../../../components/userInput"; // Adjust path as per your project structure
import Button from "../../../components/button"; // Adjust path as per your project structure
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../../firebase'; // Assuming auth is imported correctly from firebase
import { UserContext } from "../../../components/userContext"; // Adjust path as per your project structure

const Login = () => {
  const navigate = useNavigate();
  const { setUserProfile } = useContext(UserContext); // Assuming you have UserContext set up correctly
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      // Upon successful login, fetch additional user data if needed
      const user = userCredential.user;
      setUserProfile({
        uid: user.uid,
        email: user.email, // Add additional fields as needed
        // Fetch other user profile data here as per your application
      });

      // Navigate to profile page
      navigate('/profile');
    } catch (error) {
      console.error("Error logging in:", error.message);
      // Handle login error, show message to user
    }
  };

  return (
    <div className="register-page">
      <img src={logo} className="logo" alt="Logo" />
      <div className="register-container">
        <h1>Login</h1>
        <UserInput
          placeholder='Email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <UserInput
          placeholder='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <p><Link to='/forgot-password'>Forgot password?</Link></p>
        <Button
          onClick={handleLogin}
          text='Login'
        />
        <Link to='/register'>Register, if you don't have an account</Link>
      </div>
    </div>
  );
};

export default Login;
