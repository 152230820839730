import React from 'react';
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './components/userContext';
import Welcome from './pages/authentication/welcome/welcome';
import Register from './pages/authentication/register/register';
import Username from './pages/authentication/username/username';
import UpdateProfile from './pages/profile/updateProfile/updateProfile';
import UserProfile from './pages/profile/userProfile/userProfile';
import UploadPhoto from './pages/profile/uploadPhoto/uploadPhoto';
import Home from './pages/home/home';
import Settings from './pages/settings/settings';
import OtherProfile from './pages/profile/otherProfile';
import Followers from './pages/profile/followers/followers';
import Following from './pages/profile/followers/following';
import OtherFollower from './pages/profile/followers/otherFollowers';
import OtherFollowing from './pages/profile/followers/ptherFollowing';
import Login from './pages/authentication/login/login';

const App = () => {
  return (
    <UserProvider>
    <Router>
      <Routes>
        <Route path='/' element={<Welcome />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/username' element={<Username />} />
          <Route path='/updateprofile' element={<UpdateProfile />} />
          <Route path='/profile' element={<UserProfile />} />
          <Route path='/upload' element={<UploadPhoto />} />
          <Route path='/home' element={<Home />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/profile/:username' element={<OtherProfile />} />
          <Route path='/followers' element={<Followers />} />
          <Route path='/following' element={<Following />} />
          <Route path='/followers/:username' element={<OtherFollower />} />
          <Route path='/following/:username' element={<OtherFollowing />} />
      </Routes>
    </Router>
    </UserProvider>
  )
}

export default App;