/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState, useCallback } from "react";
import './userProfile.css';
import NavBar from "../../../components/navbar/navbar";

import { UserContext } from "../../../components/userContext";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

const UserProfile = () => {
  const { userProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const [userPhotos, setUserPhotos] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);


  const fetchUserPhotos = useCallback(async () => {
    if (!userProfile || !userProfile.uid) {
      console.log("User profile or UID not available");
      return;
    }

    console.log("Fetching photos for user:", userProfile.uid);
    const photosCollection = collection(db, `users/${userProfile.uid}/userPhotos`);
    const photoDocs = await getDocs(photosCollection);

    if (photoDocs.empty) {
      console.log("No photos found for user.");
    } else {
      const photos = photoDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Fetched photos:", photos);
      setUserPhotos(photos); // Update state with fetched photos
    }
  }, [userProfile]);

  const fetchFollowerAndFollowingCount = useCallback(async () => {
    if (!userProfile || !userProfile.uid) {
      console.log("User profile or UID not available");
      return;
    }

    try {
      // Fetch followers count
      const followersCollection = collection(db, `users/${userProfile.uid}/followers`);
      const followersSnapshot = await getDocs(followersCollection);
      setFollowerCount(followersSnapshot.size);

      // Fetch following count
      const followingCollection = collection(db, `users/${userProfile.uid}/following`);
      const followingSnapshot = await getDocs(followingCollection);
      setFollowingCount(followingSnapshot.size);
    } catch (error) {
      console.error("Error fetching follower/following count:", error);
    }
  }, [userProfile]);  

  useEffect(() => {
    fetchUserPhotos();
    fetchFollowerAndFollowingCount();

    // Listen for custom event to update photos
    const updatePhotosHandler = () => {
      console.log("photosUpdated event received");
      setUpdateFlag((prev) => !prev); // Toggle updateFlag to trigger re-fetch
    };
    window.addEventListener('photosUpdated', updatePhotosHandler);

    return () => {
      window.removeEventListener('photosUpdated', updatePhotosHandler);
    };
  }, [fetchUserPhotos, fetchFollowerAndFollowingCount]); // fetchUserPhotos included in dependency array

  // Re-fetch photos when updateFlag changes
  useEffect(() => {
    fetchUserPhotos();
  }, [updateFlag, fetchUserPhotos, fetchFollowerAndFollowingCount]);

  const handleEditProfile = () => {
    navigate(`/updateprofile`);
  };

  const handleFollowers = () => {
    navigate('/followers')
  }
  
  const handleFollowing = () => {
    navigate('/following')
  }

  return (
    <div className="profile-page">
      <NavBar />
      <div className="profile-container">
        <p className="profile-username">@{userProfile.username}</p>
        <p className="profile-name">{userProfile.name}</p>
        <p className="edit-profile" onClick={handleEditProfile}>Edit <br /> Profile </p>
        <div className="profilephoto">
          {userProfile.profilePhoto ? (
            <img src={userProfile.profilePhoto} alt="Profile" className="profile-photo-img" />
          ) : (
            <div className="profile-photo-placeholder"></div>
          )}
        </div>
        <div className="profile-bio">
          <p>{userProfile.bio}</p>
        </div>
        <p className="followers" onClick={handleFollowers}>FOLLOWERS</p>
        <p className="following" onClick={handleFollowing}>FOLLOWING</p>
        <p className="followers-num" onClick={handleFollowers}> {followerCount} </p>
        <p className="following-num" onClick={handleFollowing}> {followingCount} </p>
        
      </div>
      <div className="profile-gallery">
        {userPhotos.length > 0 ? (
          userPhotos.map(photo => (
            <div key={photo.id} className="profile-gallery-photo">
              <img src={photo.url} alt="User photo" className="gallery-photo-img" />
            </div>
          ))
        ) : null /* Removed "No photos available." message */}
      </div>
    </div>
  );
};

export default UserProfile;
