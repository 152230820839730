/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import homeIcon from '../../assets/icons/Home.png'
import settingsIcon from '../../assets/icons/SettingIcon.png'
import notificationIcon from '../../assets/icons/notification.png'
import uploadIcon from '../../assets/icons/upload.png'
import profileIcon from '../../assets/icons/profile.png'
import './navbar.css'
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();

  const handleUploadPhoto = () => {
    navigate('/upload');
  }

  const handleHomePage = () => {
    navigate('/home');
  }

  const handleSettings = () => {
    navigate('/settings');
  }

  const handleNavProfile = () => {
    navigate('/profile')
  }
   
  return (
    <div className="nav-container">
      <img src={homeIcon} className="homeIcon" onClick={handleHomePage}/>
      <img src={settingsIcon} className="settingIcon" onClick={handleSettings}/>
      <img src={notificationIcon} className="notificationIcon" />
      <img src={uploadIcon} className="uploadIcon" onClick={handleUploadPhoto}/>
      <img src={profileIcon} className="profileIcon" onClick={handleNavProfile} />
    </div>
  )
}

export default NavBar;
