import React from "react";
import "./homeModal.css";
import { useNavigate } from "react-router-dom";

const HomeModal = ({ photoUrl, description, username, closeModal }) => {
  const navigate = useNavigate();

  const handleOtherUsername = () => {
    navigate(`/profile/${username}`)
  }
  return (
    <div className="modal-background" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={closeModal}>&times;</span>
        <img src={photoUrl} alt="Modal" className="modal-photo" />
        <p className="home-description">{description}</p>
        <p className="home-username"onClick={handleOtherUsername}>@{username}</p>
      </div>
    </div>
  );
};

export default HomeModal;
