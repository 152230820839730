import React, { useState, useContext, useEffect, useRef } from "react";
import UserInput from "../../../components/userInput";
import './updateProfile.css';
import Button from "../../../components/button";
import { UserContext } from "../../../components/userContext";
import { doc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams, useNavigate } from "react-router-dom";

const UpdateProfile = () => {
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoFile, setProfilePhotoFile] = useState(null);
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const { currentUser, userProfile, setUserProfile } = useContext(UserContext);
  const fileInputRef = useRef(null);
  const { usernameParam } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      // Reset all state when no current user is present (i.e., on logout or new account creation)
      resetState();
    } else {
      setUsername(userProfile.username || usernameParam || '');
      setProfilePhoto(userProfile.profilePhoto || null);
      setName(userProfile.name || '');
      setBio(userProfile.bio || '');
    }
  }, [currentUser, userProfile, usernameParam]);

  const resetState = () => {
    setName('');
    setBio('');
    setProfilePhoto(null);
    setProfilePhotoFile(null);
    setUsername('');
    setError('');
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhotoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const isUsernameValid = async (newUsername) => {
    try {
      if (newUsername === userProfile.username) {
        return true; // The username hasn't changed, so it's valid
      }

      const q = query(collection(db, 'users'), where('username', '==', newUsername));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty; // Return true if no documents with the new username found
    } catch (error) {
      console.error('Error validating username:', error);
    }
    return false; // Default to false in case of errors
  };

  const handleSave = async () => {
    if (!currentUser) {
      setError('No authenticated user found');
      return;
    }

    if (!username) {
      setError('Username cannot be empty');
      return;
    }

    try {
      const usernameIsValid = await isUsernameValid(username);
      if (!usernameIsValid) {
        setError('Username is already taken');
        return;
      }

      let downloadURL = profilePhoto;

      if (profilePhotoFile) {
        const storageRef = ref(storage, `profilePhotos/${currentUser.uid}/${profilePhotoFile.name}`);
        await uploadBytes(storageRef, profilePhotoFile);
        downloadURL = await getDownloadURL(storageRef);
      }

      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        name,
        bio,
        profilePhoto: downloadURL,
        username
      });

      setUserProfile({
        ...userProfile,
        name,
        bio,
        profilePhoto: downloadURL,
        username
      });

      setError(''); // Reset error state after successful update
      console.log('Profile updated successfully');
      navigate('/profile');

      // Reset form after successful update
      resetState();
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  return (
    <div className="up-page">
      <div className="up-container">
        <h1 className="update-profile">Update Profile</h1>
        <UserInput
          placeholder='name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          className='up-name'
        />
        <div className="up-photo">
          {profilePhoto ? (
            <img src={profilePhoto} className="up-photo-preview" alt="Profile Preview" />
          ) : (
            <div className="up-photo-placeholder">No photo uploaded</div>
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="file-input"
          id="file-upload"
        />
        <label htmlFor="file-upload" className="custom-file-upload">
          Choose File
        </label>
        <textarea
          className="up-bio"
          placeholder="bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
        <UserInput
          className='up-username'
          placeholder='username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {error && <p className="error-message">{error}</p>}
        <Button
          text='Save'
          onClick={handleSave}
          className='up-save'
        />
      </div>
    </div>
  );
};

export default UpdateProfile;
